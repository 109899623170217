
import { useEffect } from 'react';
import "../styles/about.scss"

export const AboutUs = () => {

    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };



    return <div className="about-page">
        <div className="title">
            <label >About Us</label>
        </div>

        <div className='intro'>
            <p>BillReader.com is part of EIQdigital and is driven by a passion for the energy industry and a commitment to sustainable energy solutions. Founded by energy experts, our mission is to drive the digital transformation of the energy industry and contribute to the world's transition to cleaner, more sustainable energy sources.</p>
            <p>Our team has deep expertise in using digital technology to improve customer experiences, operational performance, and bottom-line results for our clients. From digital product development to data analysis and automation, we offer a broad range of services to firms of all sizes.</p>
            <p>Based in the "Energy Capital of the World", Houston, TX, esiid.io and EIQdigital is at the forefront of the digital energy revolution. We are committed to working with our clients to drive innovation and achieve their business objectives. Let us put our expertise to work for you and show you how we can make a positive impact on your business.</p>
            {/* <h3>Leadership Team</h3>
            <div className="team-list">
                <div className="team-member">
                    <div className="team-member-photo">
                        <img src={require("../images/dan.jpeg")} alt="" />
                    </div>
                    <div className="team-member-info">
                        <i>CEO</i>
                        <h4>Dan Sullivan</h4>
                        <p>Dan Sullivan is a proven business leader with over 20 years of experience in the competitive retail energy industry. Throughout his career, Dan has demonstrated a commitment to entrepreneurship and a proven track record of success in building businesses and driving growth for companies of all sizes, from start-ups to Fortune 100 companies.</p>
                        <p>In addition to his core expertise in energy, Dan has a diverse background in consumer-packaged goods, channel management, product management, technology, and consulting. This broad industry experience has equipped him with a well-rounded understanding of the many challenges and opportunities facing businesses today.</p>
                        <p>With a passion for innovation and a dedication to driving results, Dan is committed to helping shape the transformation of the energy industry.</p>
                    </div>
                </div>
                <div className="team-member">
                    <div className="team-member-photo">
                        <img src={require("../images/david.jpeg")} alt="" />
                    </div>
                    <div className="team-member-info">
                        <i>Chief Digital Officer</i>
                        <h4>David Chang</h4>
                        <p>David Chang is a seasoned Chief Digital Officer with over 15 years of experience driving digital transformation for a wide range of organizations. His expertise covers the entire digital lifecycle, from development and acquisition to marketing strategy, making him well-suited to design and deploy digital experiences that engage customers and build brand loyalty.</p>
                        <p>David is known for his innovative approach and his ability to think outside the box, consistently identifying and delivering new, invigorating solutions to generate demand. He is also a collaborative leader who effectively engages with both internal and external stakeholders at the highest levels.</p>
                        <p>With more than 50 LinkedIn endorsements from key industry professionals and business leaders, David is a respected and proven leader in the field of digital transformation.</p>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
}